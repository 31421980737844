import dynamic from "next/dynamic";
import { loadStandardContent } from "utils/api";
import { PageHead } from "components/metadata";
import Layout from "components/layout";

import { ComponentMap } from "components";
import Link from "next/link";
import Image from "next/image";
const Newsletter = dynamic(() => import("components/newsletter"));

const incentives = [
  {
    name: "Expert-Led Courses",
    imageSrc:
      "https://tailwindui.com/plus/img/ecommerce/icons/icon-exchange-simple.svg",
    description:
      "Our tutors are industry experts with years of experience in the industry.",
  },
  {
    name: "Small Group Sizes",
    imageSrc:
      "https://tailwindui.com/plus/img/ecommerce/icons/icon-shipping-simple.svg",
    description:
      "We cap our group sizes to ensure you get the attention you need to succeed.",
  },
  {
    name: "Live Model Clients",
    imageSrc:
      "https://tailwindui.com/plus/img/ecommerce/icons/icon-warranty-simple.svg",
    description:
      "We provide live model clients for you to practice on during your training.",
  },
  {
    name: "Advanced Online Training",
    imageSrc:
      "https://tailwindui.com/plus/img/ecommerce/icons/icon-exchange-simple.svg",
    description:
      "Leverage our digital learning platform to access course materials and resources.",
  },
];

const categories = [
  {
    name: "VTCT Level 4 Advanced Aesthetics",
    href: "https://www.cosmetic.college/courses/vtct/level-4-diploma-in-advanced-aesthetic-treatments/",
    videoSrc:
      "//videos.ctfassets.net/eo68gvto3q0j/5ZB4qjgZNoEfGHGkvOQx4Z/436cdcdc1dc4c033dd336e25938cd7a4/DERMAPLANING_REEL_handbrake_.mp4",
    imageSrc:
      "https://images.ctfassets.net/eo68gvto3q0j/4sQUUdOQljK83lkQccsB2Z/9c3779569f74209e8539c5ae4bb7b688/dermaplaning_pic_.jpg",
    imageAlt: "VTCT Level 4 Advanced Aesthetics",
    description:
      "Elevate your professional credentials with our VTCT (iTEC) Level 4 Diploma in Advanced Aesthetic Treatments, specialising in Skin Needling, Dermaplaning and Skin Peeling. ",
  },
  {
    name: "VTCT Level 5 Laser Tattoo Removal",
    href: "https://www.cosmetic.college/courses/vtct/level-5-certificate-in-laser-tattoo-removal/",
    videoSrc:
      "//videos.ctfassets.net/eo68gvto3q0j/2zbkdyAM9dY7ZzlfEm1HpP/2f9c91b70a86aa089b25bb6480b6ef61/laser-tattoo-removal-cosmetic-college.mp4",
    imageSrc:
      "https://images.ctfassets.net/eo68gvto3q0j/7D20Uc0mfHyhTVo3tGIvdA/88702142276528f9d60019ae04fca183/laser-tattoo-removal-cosmetic-college.jpg",
    imageAlt: "VTCT Level 5 Laser Tattoo Removal",
    description:
      "An intensive, practical 5-day programme designed to equip you with advanced knowledge and skills in laser technology for the effective removal of unwanted tattoos.",
  },
  {
    name: "VTCT Level 6 Mesotherapy",
    videoSrc:
      "//videos.ctfassets.net/eo68gvto3q0j/578UK4dZu8dISUdGW1tAnn/c463defd4321abcfd62c02e88692489c/Mesotherapy_Reel_-_Sequence_1.mp4",
    href: "https://www.cosmetic.college/courses/vtct/level-6-award-in-mesotherapy-treatments/",
    imageSrc:
      "https://images.ctfassets.net/eo68gvto3q0j/4XMIFbp9EgevoLdtvEfwTF/b3e124116845c5e1da545c71a2fd0863/mesotherapy.jpg",
    imageAlt: "VTCT Level 6 Mesotherapy",
    description:
      "This higher-level course focuses on the therapeutic application of skin rejuvenation techniques, delivering targeted results for a range of client concerns—from ageing skin to uneven texture",
  },
  {
    name: "VTCT Level 2 Facial and Skincare Course",
    href: "https://www.cosmetic.college/courses/vtct/level-2-facial-and-skincare-course/",
    videoSrc:
      "//videos.ctfassets.net/eo68gvto3q0j/4xFlp76DptcQfPpMZ5awyK/6c894ff44b4d1b72894e8b147ca6c802/VTCT_Level_2_Facial___Skincare_Training.mp4",
    imageSrc:
      "https://images.ctfassets.net/eo68gvto3q0j/3BTAPcoo9vGqkppjahUgSG/521e74d56c5558115588075435308c4d/Untitled_design__4_.png",
    imageAlt: "VTCT Level 2 Facial and Skincare Course",
    description:
      "A foundational program is designed to usher you into the thriving beauty industry, equipping you with the essential skills and knowledge to perform professional facial treatments and skincare services.",
  },
  {
    name: "VTCT Level 4 Laser Hair Removal",
    href: "https://www.cosmetic.college/courses/vtct/laser-hair-removal/",
    videoSrc:
      "//videos.ctfassets.net/eo68gvto3q0j/6bxBKfglA4PATkHAOLuKqz/68b6ca61f70fb2951a2f02b78b3d9151/Laser_Hair_Removal_-_Website_1.mp4",
    imageSrc:
      "https://images.ctfassets.net/eo68gvto3q0j/3fp2bgY1mgeIycFMKZu6Ff/0e2f77c49a7a2db3254391190472e1e7/Screenshot_2022-09-25_at_10.00.34.png",
    imageAlt: "VTCT Level 4 Laser Hair Removal",
    description:
      "Get qualified in laser hair removal with our VTCT Level 4 course in 4 days",
  },
  {
    name: "VTCT Level 4 Micropigmentation",
    href: "https://www.cosmetic.college/courses/vtct/level-4-certificate-in-micropigmentation/",
    videoSrc: "//videos.ctfassets.net/eo68gvto3q0j/1RkzHIiG4lTuDOiiQnBWYA/ae3172096e221b5446fcbc8575d2182a/Level_4_Micropigmentation_-_Reel.mp4",
    imageSrc:
      "https://images.ctfassets.net/eo68gvto3q0j/55dzmvNLOk5pkT5gbLAAat/46a9accfd75e46c3d2b3b0396b264cab/4.png",
    imageAlt: "VTCT Level 4 Micropigmentation",
    description:
      "In just 7 days, plus e-learning, you'll be equipped with the expertise to launch a successful career in the booming permanent makeup industry.",
  },
];

const featuredTestimonial = {
  body: "Training with Grace at Cosmetic College in Microblading Techniques was great. She has a wealth of knowledge and experience in not only the procedure, anatomy and health and safety but she also provided us with business and marketing tips too. Surely an essential part of such a competitive market. I would definitely recommend Grace and Cosmetic College for training.",
  link: "https://g.co/kgs/3D3nY23",
  author: {
    name: "Analisa",
    handle: "Analisa",
    imageUrl:
      "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=1024&h=1024&q=80",
  },
};

const testimonials = [
  [
    [
      {
        body: "I would highly recommend Cosmetic college. I have attended two of their courses now and will continue to do so. My last training was with Kara and she was so friendly and very supportive.",
        link: "https://g.co/kgs/rVSSh8U",
        author: {
          name: "Stacy Fuller",
          handle: "lesliealexander",
          imageUrl:
            "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
        },
      },
      {
        body: "Kelsey was excellent. I learnt a huge amount and gained valuable experience. Thoroughly enjoyed my morning.",
        link: "https://g.co/kgs/TWQJsoh",
        author: {
          name: "Tracy Harrington",
          handle: "lesliealexander",
          imageUrl:
            "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
        },
      },
      {
        body: "I had my first training with Cosmetic College and I was so greatful to find this training college. Samia was amazing teacher, the course was very focus in practice, and the groups are small so that's very helpful. The only thing I wanted to practice more was the foxy eyes, we know the base know to do it but I wish we could practice ones to feel more confidente to do It in the clinic because I'm the course it's include. Thank you very much!",
        link: "https://g.co/kgs/gSKRR23",
        author: {
          name: "Cristina",
          handle: "lesliealexander",
          imageUrl:
            "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
        },
      },
      // More testimonials...
    ],
    [
      {
        body: "Was really happy with my course and my tutor Kelsey. Parking was easy in the Sainsbury's behind (as they suggest on website). Glad I picked this college",
        link: "https://g.co/kgs/K9YSDpu",
        author: {
          name: "Megan",
          handle: "lindsaywalton",
          imageUrl:
            "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
        },
      },
      {
        body: "Cosmetic College is a wonderful place . Very cozy and comfortable.The tutors are amazing professionals. They create in a student feeling of confidence in the procedures which they are learning to do .",
        link: "https://g.co/kgs/zCX7V1L",
        author: {
          name: "Desislava Dodeva",
          handle: "lindsaywalton",
          imageUrl:
            "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
        },
      },
      {
        body: "The Cosmetic College Team are Great, super helpful and very informative!",
        link: "https://g.co/kgs/oQfG6Hv",
        author: {
          name: "Sarah Smith",
          handle: "lindsaywalton",
          imageUrl:
            "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
        },
      },
      // More testimonials...
    ],
  ],
  [
    [
      {
        body: "Fantastic course, the pre learning was easy to find and complete, the college was easy to find with parking nearby and the instructor was brilliant.",
        link: "https://g.co/kgs/jZX4DJd",
        author: {
          name: "Sian Toms",
          handle: "tomcook",
          imageUrl:
            "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
        },
      },
      {
        body: "Brilliant Lemon Bottle training! Very informative! Il l definitely be back for more aesthetic training in near future..",
        link: "https://g.co/kgs/qneD9vN",
        author: {
          name: "Nailya Cook",
          handle: "tomcook",
          imageUrl:
            "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
        },
      },
      // More testimonials...
    ],
    [
      {
        body: "I have just accomplished my Foundation Anti Wrinkle course and I am absolutely thrilled with the quality of this course. Loved it, loved it! I feel very ready to offer this treatment to my clients now😊",
        link: "https://g.co/kgs/evLdY1L",
        author: {
          name: "Beata Schoeman",
          handle: "leonardkrasner",
          imageUrl:
            "https://images.unsplash.com/photo-1519345182560-3f2917c472ef?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
        },
      },
      {
        body: "Fantastic Vitamin injection course with Charmelle. On line training  very on user friendly. Class room, practical very comprehensive.",
        link: "https://g.co/kgs/dMQZ8Sm",
        author: {
          name: "Jane Pearson",
          handle: "leonardkrasner",
          imageUrl:
            "https://images.unsplash.com/photo-1519345182560-3f2917c472ef?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
        },
      },
      {
        body: "Excellent course on injectables (fillers, botulinum toxin etc.), starting with a theoretical part online, which helped me with the organisation as I live in Switzerland, followed by practical training on site with numerous models available. I was able to do most of the injections myself, benefiting greatly from Charmellle's clear and precise teaching, which I really appreciated and would recommend.",
        link: "https://g.co/kgs/eoPx2CA",
        author: {
          name: "Sophie Richter",
          handle: "leonardkrasner",
          imageUrl:
            "https://images.unsplash.com/photo-1519345182560-3f2917c472ef?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
        },
      },
      // More testimonials...
    ],
  ],
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Page(props) {
  const {
    fields: { title, type, url, content },
  } = props.entry;

  return (
    <>
      <PageHead metadata={props.entry.fields.metadata} />
      <Layout siteData={props.siteData}>
        <section className="hidden lg:flex relative h-[550px] flex-col items-start justify-end text-center text-white py-0 px-3">
          <div className="video-docker absolute top-0 left-0 right-0 w-full h-full overflow-hidden bg-black">
            <video
              className="min-w-full min-h-full inset-0 absolute object-contain"
              src="/video/hifu-training-cosmetic-college.mp4"
              playsInline
              autoPlay
              muted
              loop
              controls
              controlsList="nodownload nofullscreen noremoteplayback"
              onContextMenu={() => false}
            />
          </div>
          <div className="video-content max-w-2xl text-left p-6 pb-12">
            <h1>
              <span className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                <span className="inline text-white">
                  Expert Training for Aspiring Aesthetic
                </span>
                <span className="inline text-brand-300"> Specialists</span>
              </span>
            </h1>
            <div className="mt-10 flex flex-col space-y-5 sm:space-y-0 sm:flex-row items-center gap-x-6">
              <Link
                href="/courses"
                className="w-full md:w-max rounded-md bg-brand-600 px-6 py-4 text-sm font-semibold text-white shadow-sm hover:bg-brand-500 outline outline-2 outline-offset-2 outline-brand-600 gradient-border transition-colors duration-200 hover:outline-brand-500 text-center"
              >
                <span className="relative z-[1] text-center">
                  Browse Training Courses
                </span>
              </Link>
              <Link
                href="/request-a-callback"
                className="w-full md:w-max group bg-white rounded-md text-sm font-semibold px-6 py-4 leading-6 eading-6 text-gray-600 ring-2 ring-gray-900/10 hover:ring-brand-600 shadow-lg  transition-all duration-200 hover:shadow-lg hover:shadow-brand-200 text-center"
              >
                Speak With An Advisor{" "}
                <span
                  aria-hidden="true"
                  className="group-hover:pl-1 transition-all duration-150"
                >
                  →
                </span>
              </Link>
              {/* <button
                    type="button"
                    onClick={() => {
                      setState({ ...state, showCallbackModal: true });
                    }}
                    className="w-full md:w-max group rounded-md text-sm font-semibold px-6 py-4 leading-6 eading-6 text-gray-600 ring-2 ring-gray-900/10 hover:ring-brand-600 shadow-lg shadow-brand-100 transition-all duration-200 hover:shadow-lg hover:shadow-brand-200 text-center"
                  >
                    Speak with an advisor{" "}
                    <span
                      aria-hidden="true"
                      className="group-hover:pl-1 transition-all duration-150"
                    >
                      →
                    </span>
                  </button> */}
            </div>
          </div>
        </section>

        <section className="flex flex-col lg:hidden">
          <div>
            <video
              className="aspect-w-16"
              src="/video/hifu-training-cosmetic-college.mp4"
              autoPlay
              muted
              loop
              controls
              playsInline
              controlsList="nodownload nofullscreen noremoteplayback"
              onContextMenu={() => false}
            ></video>
          </div>
          <div className="px-6 flex-1 pt-6">
            <h1>
              <span className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                <span className="inline text-gray-900">
                  Expert Training for Aspiring Aesthetic
                </span>
                <span className="inline text-brand-600"> Specialists</span>
              </span>
            </h1>
            <p className="pt-3">
              Accelerate your career at Cosmetic College with our expert-led
              training programs in aesthetics and cosmetic injectables. Dive
              into a learning experience that blends theory with practical
              application, and emerge as a leading professional in aesthetic
              treatments.
            </p>
            <div className="mt-10 flex flex-col space-y-5 sm:space-y-0 sm:flex-row items-center gap-x-6 pb-4">
              <Link
                href="/courses"
                className="w-full md:w-max rounded-md bg-brand-600 px-6 py-4 text-sm font-semibold text-white shadow-sm hover:bg-brand-500 outline outline-2 outline-offset-2 outline-brand-600 gradient-border transition-colors duration-200 hover:outline-brand-500 text-center"
              >
                <span className="relative z-[1] text-center">
                  Browse Training Courses
                </span>
              </Link>
              <Link
                href="/request-a-callback"
                className="w-full md:w-max group bg-white rounded-md text-sm font-semibold px-6 py-4 leading-6 eading-6 text-gray-600 ring-2 ring-gray-900/10 hover:ring-brand-600 shadow-lg shadow-brand-100 transition-all duration-200 hover:shadow-lg hover:shadow-brand-200 text-center"
              >
                Speak With An Advisor{" "}
                <span
                  aria-hidden="true"
                  className="group-hover:pl-1 transition-all duration-150"
                >
                  →
                </span>
              </Link>
            </div>
          </div>
        </section>

        <div className="bg-white py-16 sm:pb-8 sm:pt-24">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mb-8 flex justify-center">
              <h1>
                <span className="mt-1 block text-2xl md:text-2xl tracking-tight font-extrabold sm:text-2xl xl:text-2xl">
                  <span className="inline text-gray-900">
                    Cosmetic College is a leading provider of{" "}
                    <span className="inline text-brand-600">
                      innovative, high-quality training courses in aesthetics
                      and beauty therapy.
                    </span>{" "}
                    Our mission is simple: to equip every student with the{" "}
                    <span className="inline text-brand-600">
                      practical skills, industry insights, and professional
                      confidence
                    </span>{" "}
                    they need to excel in the rapidly evolving beauty &
                    aesthetics industry.
                  </span>
                </span>
              </h1>
            </div>
            <div className="mb-10 flex justify-center">
              <p className="relative rounded-full bg-gray-50 px-4 py-1.5 text-base text-gray-600 ring-1 ring-inset ring-gray-900/5 text-center">
                <span className="inline">
                  Last year we trained over 1100 students, accredited by
                </span>
              </p>
            </div>
            <div className="mx-auto grid max-w-lg grid-cols-3 items-center gap-x-8 gap-y-12 sm:max-w-lg sm:grid-cols-6 sm:gap-x-10 sm:gap-y-6 lg:grid-cols-3">
              <img
                alt="VTCT training courses at the Cosmetic College in London"
                src="/logo/vtct-small.png"
                width={158}
                height={48}
                className="col-span-1 max-h-12 w-full object-contain lg:col-span-1"
              />
              <img
                alt="iTEC training courses at the Cosmetic College in London"
                src="/logo/itec.png"
                width={158}
                height={48}
                className="col-span-1 max-h-12 w-full object-contain lg:col-span-1"
              />
              <img
                alt="CPD accredited training courses at the Cosmetic College in London"
                src="/logo/cpd.png"
                width={158}
                height={48}
                className="col-span-1 max-h-12 w-full object-contain lg:col-span-1"
              />
            </div>
          </div>
        </div>

        <div className="">
          <div className="mx-auto max-w-7xl px-4 py-8 sm:px-6 sm:py-16 lg:px-8">
            <div className="sm:flex sm:items-baseline sm:justify-between">
              <h2 className="text-2xl font-bold tracking-tight text-gray-900">
                Training Courses
              </h2>
              <Link
                href="/courses/"
                className="hidden text-sm font-semibold text-brand-600 hover:text-brand-800 sm:block"
              >
                Browse all courses
                <span aria-hidden="true"> &rarr;</span>
              </Link>
            </div>

            <div className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:grid-rows-2 sm:gap-x-6 lg:gap-8">
              <div className="sm:hidden aspect-[2/1] group relative overflow-hidden rounded-lg sm:row-span-2">
                <img
                  alt="VTCT training courses at the Cosmetic College in London"
                  src="https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F7D20Uc0mfHyhTVo3tGIvdA%2F88702142276528f9d60019ae04fca183%2Flaser-tattoo-removal-cosmetic-college.jpg&w=1920&q=75"
                  className="absolute size-full object-cover"
                />
                <div
                  aria-hidden="true"
                  className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-90"
                />
                <div className="absolute inset-0 flex items-end p-6">
                  <div>
                    <div className="flex items-center space-x-3 pb-2">
                      <img
                        src="/logo/vtct-small.png"
                        alt="VTCT Logo"
                        className="h-10"
                      />
                      <img
                        src="/logo/itec.png"
                        alt="VTCT Logo"
                        className="h-8"
                      />
                    </div>
                    <h3 className="font-semibold text-white">
                      <a href="/courses/vtct/">
                        <span className="absolute inset-0" />
                        <span className="sr-only">VTCT</span> Training Courses
                      </a>
                    </h3>
                    <p aria-hidden="true" className="mt-1 text-sm text-white">
                      Nationally recognised qualifications from level 2 to 7
                    </p>
                  </div>
                </div>
              </div>
              <div className="sm:block group relative overflow-hidden rounded-lg sm:row-span-2 aspect-square">
                <img
                  alt="VTCT training courses at the Cosmetic College in London"
                  src="https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F7D20Uc0mfHyhTVo3tGIvdA%2F88702142276528f9d60019ae04fca183%2Flaser-tattoo-removal-cosmetic-college.jpg&w=1920&q=75"
                  className="absolute size-full object-cover"
                />
                <div
                  aria-hidden="true"
                  className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-50"
                />
                <div className="absolute inset-0 flex items-end p-6">
                  <div>
                    <div className="flex items-center space-x-3 pb-2">
                      <img
                        src="/logo/vtct-small.png"
                        alt="VTCT Logo"
                        className="h-10"
                      />
                      <img
                        src="/logo/itec.png"
                        alt="VTCT Logo"
                        className="h-8"
                      />
                    </div>
                    <h3 className="font-semibold text-white">
                      <a href="/courses/vtct/">
                        <span className="absolute inset-0" />
                        <span className="sr-only">VTCT</span> Training Courses
                      </a>
                    </h3>
                    <p aria-hidden="true" className="mt-1 text-sm text-white">
                      Nationally recognised qualifications from level 2 to 7
                    </p>
                  </div>
                </div>
              </div>
              <div className="group relative aspect-[2/1] overflow-hidden rounded-lg sm:aspect-auto">
                <Image
                  alt="Wooden shelf with gray and olive drab green baseball caps, next to wooden clothes hanger with sweaters."
                  src="https://images.ctfassets.net/eo68gvto3q0j/4XMIFbp9EgevoLdtvEfwTF/b3e124116845c5e1da545c71a2fd0863/mesotherapy.jpg"
                  className="absolute size-full object-cover"
                  fill
                />
                <div
                  aria-hidden="true"
                  className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-50"
                />
                <div className="absolute inset-0 flex items-end p-6">
                  <div>
                    <div className="flex items-center space-x-1 pb-2">
                      <img src="/logo/cpd.png" alt="CPD Logo" className="h-8" />
                      <h3 className="font-semibold text-white">
                        <a href="/courses/aesthetics/">
                          <span className="absolute inset-0" />
                          <span className="sr-only">CPD</span>
                          Accredited Aesthetics Training
                        </a>
                      </h3>
                    </div>
                    <p aria-hidden="true" className="mt-1 text-sm text-white">
                      Learn advanced aesthetics techniques and protocols
                    </p>
                  </div>
                </div>
              </div>
              <div className="group relative aspect-[2/1] overflow-hidden rounded-lg sm:aspect-auto">
                <Image
                  alt="Semi Permanent Makeup Training Courses at the Cosmetic College in London"
                  src="https://images.ctfassets.net/eo68gvto3q0j/7eihTWsHeY8qorsyy0ZW8M/ada8630f90c50e354d3ae8c5be5f28e6/2.png"
                  className="absolute size-full object-cover"
                  fill
                />
                <div
                  aria-hidden="true"
                  className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-50"
                />
                <div className="absolute inset-0 flex items-end p-6">
                  <div>
                    <h3 className="font-semibold text-white">
                      <a href="https://www.cosmetic.college/courses/semi-permanent-makeup/">
                        <span className="absolute inset-0" />
                        Permanent Makeup Training Courses
                      </a>
                    </h3>
                    <p aria-hidden="true" className="mt-1 text-sm text-white">
                      Start your career in permanent makeup
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-6 sm:hidden">
              <a
                href="/courses/"
                className="block text-sm font-semibold text-brand-600 hover:text-brand-800"
              >
                Browse all courses
                <span aria-hidden="true"> &rarr;</span>
              </a>
            </div>
          </div>
        </div>

        <div className="bg-gray-50">
          <div className="mx-auto max-w-7xl py-24 sm:px-2 sm:py-24 lg:px-4">
            <div className="mx-auto max-w-2xl px-4 lg:max-w-none">
              <div className="grid grid-cols-1 items-center gap-x-16 gap-y-10 lg:grid-cols-2">
                <div>
                  <h2 className="text-4xl font-bold tracking-tight text-gray-900">
                    Discover Your Path in the World of{" "}
                    <span className="inline text-brand-600">Aesthetics</span>
                  </h2>
                  <p className="mt-4 text-gray-500">
                    Whether you are looking to begin a&nbsp;
                    <strong className="font-medium text-black">
                      new career in beauty, semi permanent makeup or aesthetics
                    </strong>
                    &nbsp;or you are looking to&nbsp;
                    <strong className="font-medium text-black">
                      advance your current skillset to the next level
                    </strong>
                    , the team at Cosmetic College have the expertise and
                    experience for you to grow and succeed.
                  </p>
                </div>
                <img
                  alt=""
                  src="https://images.ctfassets.net/eo68gvto3q0j/2TKMxgDrntTf4nBYmFKw6E/895fb83fec600b4c3ec9844ec2771d62/VTCT_Level_4_Radiofrequency___Ultrasound_Training_At_The_Cosmetic_College.png"
                  className="aspect-[3/2] w-full rounded-lg bg-gray-100 object-cover"
                />
              </div>
              <div className="mt-16 pt-6 grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4 border-t">
                {incentives.map((incentive) => (
                  <div key={incentive.name} className="sm:flex lg:block">
                    <div className="mt-4 sm:ml-6 sm:mt-0 lg:ml-0 lg:mt-6">
                      <h3 className="text-sm font-medium text-gray-900">
                        {incentive.name}
                      </h3>
                      <p className="mt-2 text-sm text-gray-500">
                        {incentive.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white">
          <div className="mx-auto max-w-xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
            <h2 className="text-2xl font-bold tracking-tight text-gray-900">
              Featured Training Courses
            </h2>
            <div className="mt-10 space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
              {categories.map((category) => (
                <a
                  key={category.name}
                  href={category.href}
                  className="group block"
                >
                  {category.videoSrc ? (
                    <video
                      src={category.videoSrc}
                      playsInline
                      autoPlay
                      muted
                      loop
                      controls
                      controlsList="nodownload nofullscreen noremoteplayback"
                      onContextMenu={() => false}
                      poster={category.imageSrc}
                      className="aspect-[3/2] w-full rounded-lg object-cover group-hover:opacity-75 lg:aspect-[5/6]"
                    />
                  ) : (
                    <img
                      alt={category.imageAlt}
                      src={category.imageSrc}
                      className="aspect-[3/2] w-full rounded-lg object-cover group-hover:opacity-75 lg:aspect-[5/6]"
                    />
                  )}
                  <h3 className="mt-4 text-base font-semibold text-gray-900">
                    {category.name}
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">
                    {category.description}
                  </p>
                </a>
              ))}
            </div>
          </div>
        </div>

        <div className="relative isolate bg-white pb-32 pt-16 sm:pt-16">
          <div
            aria-hidden="true"
            className="absolute inset-x-0 top-1/2 -z-10 -translate-y-1/2 transform-gpu overflow-hidden opacity-30 blur-3xl"
          >
            <div
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
              className="ml-[max(50%,38rem)] aspect-[1313/771] w-[82.0625rem] bg-gradient-to-tr from-[#8affed] to-[#4cb3f4]"
            />
          </div>
          <div
            aria-hidden="true"
            className="absolute inset-x-0 top-0 -z-10 flex transform-gpu overflow-hidden pt-32 opacity-25 blur-3xl sm:pt-40 xl:justify-end"
          >
            <div
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
              className="ml-[-22rem] aspect-[1313/771] w-[82.0625rem] flex-none origin-top-right rotate-[30deg] bg-gradient-to-tr from-[#8affed] to-[#4cb3f4] xl:ml-0 xl:mr-[calc(50%-12rem)]"
            />
          </div>
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl text-center">
              <h2 className="text-base/7 font-semibold text-brand-600">
                Testimonials
              </h2>
              <p className="mt-2 text-balance text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                We have worked with thousands of amazing learners
              </p>
            </div>
            <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 grid-rows-1 gap-8 text-sm/6 text-gray-900 sm:mt-20 sm:grid-cols-2 xl:mx-0 xl:max-w-none xl:grid-flow-col xl:grid-cols-4">
              <figure className="rounded-2xl bg-white shadow-lg ring-1 ring-gray-900/5 sm:col-span-2 xl:col-start-2 xl:row-end-1">
                <blockquote className="p-6 text-lg font-semibold tracking-tight text-gray-900 sm:p-12 sm:text-xl/8">
                  <p>{`“${featuredTestimonial.body}”`}</p>
                </blockquote>
                <figcaption className="flex flex-wrap items-center gap-x-4 gap-y-4 border-t border-gray-900/10 px-6 py-4 sm:flex-nowrap">
                  <div className="flex-auto">
                    <div className="text-brand-600 font-medium">
                      <a
                        href={featuredTestimonial.link}
                        target="_blank"
                        rel="noopener"
                      >
                        {featuredTestimonial.author.name}
                      </a>
                    </div>
                  </div>
                </figcaption>
              </figure>
              {testimonials.map((columnGroup, columnGroupIdx) => (
                <div
                  key={columnGroupIdx}
                  className="space-y-8 xl:contents xl:space-y-0"
                >
                  {columnGroup.map((column, columnIdx) => (
                    <div
                      key={columnIdx}
                      className={classNames(
                        (columnGroupIdx === 0 && columnIdx === 0) ||
                          (columnGroupIdx === testimonials.length - 1 &&
                            columnIdx === columnGroup.length - 1)
                          ? "xl:row-span-2"
                          : "xl:row-start-1",
                        "space-y-8"
                      )}
                    >
                      {column.map((testimonial) => (
                        <figure
                          key={testimonial.author.handle}
                          className="rounded-2xl bg-white p-6 shadow-lg ring-1 ring-gray-900/5"
                        >
                          <blockquote className="text-gray-900">
                            <p>{`“${testimonial.body}”`}</p>
                          </blockquote>
                          <figcaption className="mt-3 flex items-center gap-x-4">
                            <div>
                              <div className="text-brand-600 font-medium">
                                <a
                                  href={testimonial.link}
                                  target="_blank"
                                  rel="noopener"
                                >
                                  {testimonial.author.name}
                                </a>
                              </div>
                            </div>
                          </figcaption>
                        </figure>
                      ))}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>

        {Array.isArray(content) &&
          content.map(({ fields, sys }, index) => {
            if (ComponentMap[sys.contentType.sys.id]) {
              const Cmp = ComponentMap[sys.contentType.sys.id];
              return (
                <Cmp
                  key={`${index}-${sys.contentType.sys.id}`}
                  fields={fields}
                  first={index === 0}
                  sys={sys}
                  includes={props.includes}
                />
              );
            }
            console.log(`NOT FOUND: ${sys.contentType.sys.id}`);
            return null;
          })}
        <Newsletter />
      </Layout>
    </>
  );
}

export async function getStaticProps({ params, preview = false }) {
  const contentful = require("contentful");
  const client = contentful.createClient({
    space: process.env.NEXT_CONTENTFUL_SPACE || "",
    accessToken: process.env.NEXT_CONTENTFUL_KEY || "",
  });

  const entry = await client.getEntry("5QAuKRxPhoABYixnFUIJqv", { include: 3 });
  const siteData = await loadStandardContent();

  return {
    props: {
      entry: entry,
      includes: entry.includes || {},
      siteData,
    },
  };
}
